import React, { Component } from 'react';
import LoadingIcons from 'react-loading-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube, faGlobe } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faArrowUp, faExclamationTriangle, faGlobeAmericas, faRefresh, faTemperatureEmpty } from '@fortawesome/free-solid-svg-icons';
import { Player } from '@lottiefiles/react-lottie-player';
import RecipeGrid from '../../components/RecipeGrid';
import RecipeService from '../../services/recipe';
import config from '../../utils/config.js';
import loader from '../../loader/main_loading_animation.json';
import ReactGA from "react-ga4";

import '../../css/recipes.css';
import MenuBar from './MenubarChris.js';

const RECIPES_PER_PAGE = 12;
const TOTAL_RECIPES_LIMIT = 800;
const defaultRequestOptions = {
    offset: 0,
    hideDetails: true,
    limit: RECIPES_PER_PAGE,
};



class ChrisRecipePageLogged extends Component {
    constructor(props) {
        super(props);
        this.titleRef = React.createRef(); // Ref for the title element

        document.title = `Ladle - Recipes by Chris De La Rosa`;

        this.state = {
            recipes: undefined,
            contestant: false,
            user: {
                photo: "https://chrisdelarosa.com/wp-content/uploads/2022/01/about.jpg",
                name: "Chris De La Rosa",
                bio: `A deep-rooted and genuine love of Caribbean cooking made self-taught chef Chris De La Rosa a staple in millions of kitchens. A down-to-earth, relatable approach to raising the profile of Caribbean cuisine, meanwhile, has turned him into a highly sought-after brand ambassador and partner.
After launching his website CaribbeanPot.com in 2009, Chris became an originator in the creation of Caribbean culinary content. While he initially intended the site as a place to document family recipes for his daughters, it quickly grew to amass a dedicated following.
 Today CaribbeanPot.com receives 785K page views per month. Its Facebook page has 350K fans; YouTube has 812K subscribers and 93.5M views and Instagram has 67K followers. Chris with 1.5M fans each month attracts the largest fans of Caribbean culinary culture.
`, socialMediaLinks: [
                    { url: "https://youtube.com/@CaribbeanPot", icon: faYoutube },
                    { url: "https://instagram.com/CaribbeanPot", icon: faInstagram },
                    { url: "https://chrisdelarosa.com", icon: faGlobeAmericas, label: "Website" },
                ],
            },
            totalRecipesLength: 0,
            options: defaultRequestOptions,
            isLoading: true,
            error: false,
            openFAQ: null, // Tracks the currently open FAQ

        };
    }

    async componentDidMount() {
        sessionStorage.removeItem("from_chris_source")
        this.getContestent();
        this.updateOptions();
    }

    toggleFAQ = (id) => {
        this.setState((prevState) => ({
          openFAQ: prevState.openFAQ === id ? null : id, // Toggle the FAQ
        }));
      };

    async getContestent() {
        try {
            const contestObj = {
                user_email: this.props.user.email
            }
            const contestRes = await fetch(`${config.API_SERVER_URL}/api/get-contestent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contestObj),
            });
            const contestData = await contestRes.json();
            console.log(contestData, "contestant");
            if (contestData.message) {
                this.setState({
                    contestant: true,

                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    async updateOptions(existingOptions) {
        const options = existingOptions || this.state.options;
        options.limit = RECIPES_PER_PAGE;

        let totalRecipesLength = 100;
        totalRecipesLength = Math.min(totalRecipesLength, TOTAL_RECIPES_LIMIT);

        this.setState({ recipes: undefined, error: false, isLoading: true, totalRecipesLength, options });
        await this.getRecipes(options);
    }


    async getRecipes(options) {
        try {
            const allRecipes = await RecipeService.getCreatorRecipeswithoutuser(options, this.state.user);
            this.setState({ allRecipes, isLoading: false, error: false, recipes: allRecipes.slice(0, RECIPES_PER_PAGE) });
        } catch (err) {
            this.setState({ isLoading: false, error: err.message });
        }
    }

    handleNextClick = () => {
        const { options, totalRecipesLength, allRecipes } = this.state;
        const newOffset = options.offset + RECIPES_PER_PAGE;

        if (newOffset < totalRecipesLength) {
            this.setState({
                options: { ...options, offset: newOffset },
                recipes: allRecipes.slice(newOffset, newOffset + RECIPES_PER_PAGE),
            }, () => {
                this.titleRef.current.scrollIntoView({ behavior: 'smooth' });
            });
        }
    };

    handlePreviousClick = () => {
        const { options, allRecipes } = this.state;
        const newOffset = Math.max(0, options.offset - RECIPES_PER_PAGE);

        if (newOffset >= 0) {
            this.setState({
                options: { ...options, offset: newOffset },
                recipes: allRecipes.slice(newOffset, newOffset + RECIPES_PER_PAGE),
            }, () => {
                this.titleRef.current.scrollIntoView({ behavior: 'smooth' });
            });
        }
    };

    renderProfileSection() {
        const { user } = this.state;
        return (
            <>
                <div className="mt-4 profile-section flex flex-col md:flex-row items-start justify-between p-6 sm:p-10 bg-white shadow-lg rounded-lg mb-6 sm:mb-10 border border-gray-200 max-w-[95%] sm:max-w-[78%] mx-auto">

                    <img
                        src={user.photo}
                        alt={`${user.name}'s profile`}
                        className="w-32 h-32 sm:w-48 sm:h-48 md:w-56 md:h-56 rounded-full border-4 border-green-600 shadow-md mr-0 md:mr-10 mb-4 md:mb-0 object-cover"
                    />

                    <div className="text-section flex-1">
                        <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 mb-4">{user.name}</h2>
                        <p className="text-sm sm:text-lg text-gray-700 leading-relaxed mb-4 sm:mb-6 overflow-auto max-h-48 sm:max-h-72 md:max-h-80 pr-0 sm:pr-3">
                            {user.bio}
                        </p>

                        <div className="social-icons flex space-x-4 sm:space-x-6 mb-4 sm:mb-6">
                            {user.socialMediaLinks.map((link, index) => (
                                <a href={link.url} key={index} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-green-600 transition duration-300" aria-label={link.label || "Social media link"}>
                                    <FontAwesomeIcon icon={link.icon} className="text-2xl sm:text-3xl" />
                                </a>
                            ))}
                        </div>

                        <div className='text-slate-600 mb-3 mt-2'> {this.state.contestant ? "You are registered for the contest" : ""}</div>

                        <div className='flex d-lg'>
                            <div className=''>
                                <button
                                    onClick={() => {
                                        ReactGA.event({
                                            category: "Explore-Ladle",
                                            action: "Explore Ladle button clicked from Chris logged in page",
                                            label: `Chris Recipes Page - Logged In User ${this.props.user.email}`
                                        })
                                        window.location.href = "/";
                                    }}

                                    className={`enter-contest-btn w-full sm:w-auto px-4 py-2 sm:px-6 sm:py-3  bg-green-600 hover:bg-green-700 text-white font-bold rounded-lg shadow-md transition duration-300`}
                                >
                                    Explore Ladle
                                </button>
                            </div>
                        </div>
                        <div className='flex d-sm'>

                            {/* <div>
                                <button
                                    onClick={async () => {
                                        if (!this.state.contestant) {
                                            sessionStorage.setItem("from_chris_source", "true");
                                            ReactGA.send({
                                                event_category: 'button_click',
                                                event_action: 'button_clicked',
                                                event_label: 'Enter Contest Button clicked',
                                            });
                                            const contestObj = {
                                                user_email: this.props.user.email,
                                                creator_name: "chris"
                                            }
                                            const contestRes = await fetch(`${config.API_SERVER_URL}/api/contest`, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify(contestObj),
                                            });
                                            const contestData = await contestRes.json();

                                            console.log('Contest created successfully!', contestData);
                                            sessionStorage.removeItem("from_chris_source");
                                            window.location.reload();
                                        } else {
                                            // go to ref
                                            this.titleRef.current.scrollIntoView({ behavior: 'smooth' });

                                        }
                                    }}
                                    className={`enter-contest-btn w-full sm:w-auto px-4 py-2 sm:px-6 sm:py-3 ${!this.state.contestant ? "bg-green-600 hover:bg-green-700" : "bg-slate-600 hover:bg-slate-700"} text-white font-bold rounded-lg shadow-md transition duration-300`}
                                >
                                    {this.state.contestant ? <div> <span>See Chris Recipes &nbsp;</span> <FontAwesomeIcon icon={faArrowDown} />  </div> : "Enter the Contest"}
                                </button>
                            </div> */}

                            <div className=''>
                                <button
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                    className={`enter-contest-btn w-full sm:w-auto px-4 py-2 sm:px-6 sm:py-3   ${this.state.contestant ? 'hover:bg-green-700 bg-green-600' : "hover:bg-green-700 bg-slate-600"} text-white font-bold rounded-lg shadow-md transition duration-300`}
                                >
                                    Explore Ladle
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderFAQs() {
        const faqs = [
          {
            id: "faq1",
            title: "Who is eligible?",
            content:
              "Open to residents of the 50 United States and D.C., 18 years of age and older as of November 13, 2024, except for employees of Ladle Cooking Inc. (\"Sponsor\"), CaribbeanPot, their affiliates, subsidiaries, and agencies (collectively \"Promotion Parties\"), and members of their immediate family or persons living in the same household. Void where prohibited."
          },
          {
            id: "faq2",
            title: "Timing of the contest",
            content:
              "The contest begins on November 13, 2024, at 12:00 pm PT and ends on December 5, 2024, at 5:00 pm PT. A random drawing will be held on December 6, 2024."
          },
          {
            id: "faq3",
            title: "How to participate?",
            content:
              "Log on to <a href='https://app.ladle.cooking/consumer/recipes/creator/chris-de-la-rosa' class='text-green-200 underline' target='_blank'>https://app.ladle.cooking/consumer/recipes/creator/chris-de-la-rosa</a> and click on the \"Enter the Contest\" icon for the Holiday Cooking Lesson with CaribbeanPot Sweepstakes. Follow the on-screen entry instructions. You may enter only once, and no more than one entry per person is permitted."
          },
          {
            id: "faq4",
            title: "Prize description",
            content:
              "The winner will receive a one-hour cooking lesson via Zoom with Chris De La Rosa, owner of the CaribbeanPot social media accounts, in December 2024. The lesson will help the winner prepare and personalize a meal for guests. Total prize value: $250."
          },
          {
            id: "faq5",
            title: "Odds of winning",
            content:
              "The odds of winning depend on the number of eligible entries received, as it is a random drawing."
          },
        ];
    
        return (
          <div className='flex justify-center mt-3'>
            <div className="max-w-4xl w-full bg-[#2a7c44] text-white rounded-lg shadow-lg p-8">
              <h1 className="text-3xl font-bold text-center mb-6">Contest FAQs</h1>
    
              <div className="space-y-4">
                {faqs.map(({ id, title, content }) => (
                  <div key={id} className="bg-[#1e5935] rounded-lg">
                    <button
                      className="w-full text-left px-4 py-3 font-bold text-lg flex items-center justify-between focus:outline-none"
                      onClick={() => this.toggleFAQ(id)}
                    >
                      {title}
                      <FontAwesomeIcon
                        icon={this.state.openFAQ === id ? faArrowUp : faArrowDown}
                        className="text-lg transition-transform duration-300"
                      />
                    </button>
                    {this.state.openFAQ === id && (
                      <div
                        className="px-4 pb-4"
                        dangerouslySetInnerHTML={{ __html: content }}
                      ></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    
    renderPaginationControls() {
        const { options, totalRecipesLength } = this.state;
        const disablePrevious = options.offset === 0;
        const disableNext = options.offset + RECIPES_PER_PAGE >= totalRecipesLength;

        return (
            <div className="pagination-controls flex justify-between sm:justify-center mt-4 sm:mt-6 space-x-2 sm:space-x-4">
                <button
                    onClick={this.handlePreviousClick}
                    disabled={disablePrevious}
                    className={`w-full sm:w-auto px-2 sm:px-4 py-2 rounded-lg font-semibold ${disablePrevious ? 'bg-gray-300' : 'bg-green-600 hover:bg-green-700 text-white'}`}
                >
                    Previous
                </button>
                <button
                    onClick={this.handleNextClick}
                    disabled={disableNext}
                    className={`w-full sm:w-auto px-2 sm:px-4 py-2 rounded-lg font-semibold ${disableNext ? 'bg-gray-300' : 'bg-green-600 hover:bg-green-700 text-white'}`}
                >
                    Next
                </button>
            </div>
        );
    }

    render() {
        const { recipes, totalRecipesLength, isLoading, error, options } = this.state;
        const loadingText = `Loading Recipes`;
        const recipesToShow = recipes ? recipes.slice(0, RECIPES_PER_PAGE) : [];

        let contentSectionHtml;
        if (error) {
            contentSectionHtml = (
                <div className="error-section text-center h-64 flex flex-col items-center justify-center">
                    <p className="text-lg mb-4"><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />Unable to load recipes. Please try again.</p>
                    <button onClick={this.reload} className="outline">
                        <FontAwesomeIcon icon={faRefresh} className="mr-2" />Retry
                    </button>
                </div>
            );
        } else if (isLoading) {
            contentSectionHtml = (
                <div className="loading-section text-center h-64 flex flex-col items-center justify-center">
                    <p className="text-lg mb-4">{loadingText}</p>
                    <Player src={loader} loop autoplay style={{ width: '40%' }} />
                </div>
            );
        } else if (recipes.length === 0) {
            contentSectionHtml = (
                <div className="no-recipes text-center h-64 flex flex-col items-center justify-center">
                    <p className="text-lg mb-4"><FontAwesomeIcon icon={faTemperatureEmpty} className="mr-2" />No recipes found</p>
                </div>
            );
        } else {
            contentSectionHtml = (
                <div className='mobile-grid'>
                    <RecipeGrid
                        recipes={recipesToShow}
                        type="consumer"
                        user={this.props.user}
                        tenant={this.props.tenant}
                        handleTagClick={this.handleTagClick}
                    />
                </div>
            );
        }

        return (
            <div className="creator-recipe-page">
                {this.renderProfileSection()}
                <h2 ref={this.titleRef} className="text-3xl font-semibold text-gray-800 text-center mb-6 change-font">Recipes by {this.state.user.name}</h2>
                <div className="content-section max-w-5xl mx-auto">
                    {contentSectionHtml}
                    {this.renderPaginationControls()}
                </div>
                {/* {this.renderFAQs()} */}
            </div>
        );
    }
}

export default ChrisRecipePageLogged;
