import React, { Component } from 'react';
import { Link } from "react-router-dom";

import logger from "../utils/log.js";

import '../css/RecipeTile.css';
import TagPill from './TagPill.js';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RecipeService from '../services/recipe';
import clsx from 'clsx';
import ReactGA from "react-ga4";


//

const log = logger('components.RecipeTile');

class RecipeTile extends Component {

    constructor(props) {

        super(props);

        let { user, tenant, recipe } = props;
        console.log('this props are', props.recipe)
        let { _id, title, subtitle, author, source, tags, media, createdAt, updatedAt, times, isFavourited } = recipe;
        const imageUrl = (media && media.heroImageUrl) ? media.heroImageUrl : "/empty-image.png";

        //console.log({msg:'RecipeTile', tenant});

        let viewsStr = 'Unknown';

        const dateAddedStr = new Date(createdAt).toLocaleDateString();
        const dateUpdatedStr = new Date(updatedAt).toLocaleDateString();
        const dateStr = dateAddedStr !== dateUpdatedStr ? `Added ${dateAddedStr}, Updated ${dateUpdatedStr}` : `Added ${dateAddedStr}`;

        // set tags to empty array if not available, otherwise filter to remove long tags for display
        tags = tags && tags.length ? tags : [];
        tags = tags.filter(tag => tag.length && tag.length <= 12).map(tag => tag.length > 12 ? tag.substring(0, 12) : tag);

        let recipeData = {
            _id,
            title,
            subtitle,
            author,
            source,
            imageUrl,
            tags,
            createdAt,
            updatedAt,
            viewsStr,
            dateStr,
            times,
            isFavourited
        };

        // create tags
        const tagPillsHtml = this.getTagPills(tags, 3);
        this.state = {
            recipeData,
            tagPillsHtml,
            relativePath: window.location.pathname
        };

    }

    handleTagClick(tagLabel) {
        this.props.handleTagClick(tagLabel.toLowerCase());
    }

    //

    getTagPills(tags, count, randomColors = false) {
        return tags.slice(0, count).map((tagLabel, index) => <TagPill key={`${tagLabel}${index}`} label={tagLabel} onClick={() => this.handleTagClick(tagLabel)} size='small' color={this.props.tenant?.brandData.color} />);
    }
    async makefavourite(id, user) {

        // if url is creator/chris-de-la-rosa
        // redirect to login with ?source=chris
        if (this.state.relativePath.includes('/chris-de-la-rosa')) {
            ReactGA.event({
                category: "Favourite",
                action: "favourite button clicked from Chris page",
                label: "Chris Recipes Page -  Recipe Favourite Button Clicked"
              })
            window.location.href = `/register?source=${this.state.relativePath.split('/')[2]}`;
            return;
        }

        this.setState(prevState => ({
            recipeData: {
                ...prevState.recipeData,
                isFavourited: !prevState.recipeData.isFavourited
            }
        }));
        try {
            await RecipeService.makefavourite(id, user);
        } catch (e) {
            this.setState(prevState => ({
                recipeData: {
                    ...prevState.recipeData,
                    isFavourited: false
                }
            }));

        }



    }

    //

    render() {

        const { recipeData, tagPillsHtml } = this.state;
        const { user } = this.props;
        const { _id, title, subtitle, author, source, imageUrl, isFavourited = false, tags, createdAt, updateAt, revenueStr, viewsStr, dateStr, times } = recipeData;

        //console.log({msg:`render ${title}, tags: ${tags}`});
        console.log("isFavourited", isFavourited)

        const recipeUrl = `/${this.props.type}/recipes/${_id}/view`;

        return (
            <div className={clsx(["recipetile mb-4 md:mb-0", {
                "flex-none lg:w-full w-[35vw]": this.state.relativePath === "/consumer/recipes"
            }])}>
                <div className='heart-icon'>
                    {isFavourited == false ? <img className='animate__animated animate__flipInX' onClick={() => this.makefavourite(_id, user)} src='/svgs/heart-white.svg' /> : <img className='animate__animated animate__fadeIn' onClick={() => this.makefavourite(_id, user)} src='/svgs/heart-red.svg' />}
                </div>
                <div className="recipetile-image h-[150px] md:h-[300px]">
                    <Link to={recipeUrl}>
                        <img src={imageUrl} className='opacity-95' alt={title} />
                    </Link>


                </div>
                <div className="recipetile-details p-3 flex flex-col items-start pb-2 h-full">
                    <Link to={recipeUrl} className='flex flex-col items-start w-full'>
                        <h2 className="recipetile-title overflow-hidden whitespace-nowrap overflow-ellipsis text-xs md:text-base lg:text-lg text-start">{title}</h2>
                        <p className='text-[#399F47] text-xs md:text-base lg:text-lg'> {times.totalTime >= "150" ? "Medium" : "Easy"}</p>
                        <div className='flex text-xs md:text-base lg:text-lg'>
                            <div className="hidden lg:block">
                                <span>Total time: </span>
                                <span>
                                    {Math.floor(times.totalTime / 60) > 0 && (
                                        <>{Math.floor(times.totalTime / 60)} {Math.floor(times.totalTime / 60) === 1 ? "Hour" : "Hours"} </>
                                    )}

                                    {times.totalTime % 60 > 0 && (
                                        <>
                                            {Math.floor(times.totalTime / 60) > 0 && (
                                                <> & </>
                                            )}
                                            {times.totalTime % 60} {times.totalTime % 60 === 1 ? "Minute" : "Minutes"}</>
                                    )}
                                </span>
                            </div>
                            <div className='lg:hidden'>
                                <span>Time: </span>
                                <span>{times.totalTime} {times.totalTime === "1" ? "Min." : "Mins."}</span>
                            </div>
                        </div>
                    </Link>
                    <div className="recipetile-tags hidden md:block">{tagPillsHtml}</div>
                </div>
            </div>
        );
    }

}

export default RecipeTile;